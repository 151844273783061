<template>
  <div class="about">
    <page-content>
      <form @submit.prevent="onSubmit()">
        <ntm-block>
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <button class="btn btn-success push-15">{{$t('save')}}</button>
        </ntm-block>
      </form>
    </page-content>
  </div>
</template>

<script>
import UserService from '../../services/user.service'
import router from '@/router'

export default {
  // required properties, field, form, model
  data () {
    return {
      form: {},
      model: {},
      gamesCount: 0,
      fields: [
        {
          key: 'firstName',
          type: 'input',
          templateOptions: {
            label: 'firstName'
          }
        },
        {
          key: 'lastName',
          type: 'input',
          templateOptions: {
            label: 'lastName'
          }
        },
        {
          key: 'fantasyName',
          type: 'input',
          templateOptions: {
            label: 'fantasyName'
          }
        },
        {
          key: 'email',
          type: 'input',
          templateOptions: {
            inputType: 'email',
            label: 'email'
          }
        }
      ],
      exporting: false
    }
  },
  beforeRouteEnter (to, from, next) {
    UserService.show(to.params.uuid).then((response) => {
      next(vm => vm.setData(response.data))
    })
  },
  computed: {
    environment () {
      return process.env.VUE_APP_ENV
    }
  },
  watch: {
    filterModel: {
      handler () {
        this.getGameCount()
      },
      immediate: false,
      deep: true
    }
  },
  methods: {
    setData (data) {
      this.model = data
    },
    onSubmit () {
      UserService.update(this.model.uuid, this.model).then((response) => {
        router.push({ name: 'users.index' })
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    }
  }
}
</script>

<template>
  <div class="table-wrapper table-responsive">
    <div class="table-loader" v-if="loading">
      <i class="table-loader-icon fa fa-cog fa-spin"></i>
    </div>
    <table class="table table-bordered table-striped table-vcenter">
      <thead>
      <tr>
        <th v-for="column in showColumns" :key="column.id" :class="columnClass(column)">
          {{ column.name ? $t('table.column.' + column.name) : '' }}
        </th>
        <th v-if="$scopedSlots.actions" class="text-center"
            style="width: 100px;">{{ $t('table.column.actions') }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr :key="item.id" v-for="item in data.data">
        <td v-for="column in showColumns" :key="column.id" :class="columnClass(column)">
          <span v-if="!['bool', 'img', 'link', 'clipboard', 'entity', 'option', 'options'].includes(column.type)"
                v-html="showInTable(item, column.prop, column.def)"></span>
          <span
            v-if="column.type === 'entity'">{{ $tc(showInTable(item, column.prop, column.def) + '.name', 1) }}</span>
          <span
            v-if="column.type === 'option'">{{ showInTable(item, column.prop, column.def) ? $t('select.options.' + showInTable(item, column.prop, column.def)) : '' }}</span>
          <template v-if="column.type === 'options'">
              <span class="badge badge-primary mr-1" v-for="(option, o) in showInTable(item, column.prop, column.def)"
                    :key="`${option}-${o}`">
                {{ $t('select.options.' + option) }}
              </span>
          </template>
          <a v-if="column.type === 'link'" :href="'http://' + showInTable(item, column.prop, column.def)"
             target="_blank">{{ showInTable(item, column.prop, column.def) }}</a>
          <button v-if="column.type === 'clipboard'"
                  class="btn btn-info d-flex justify-content-between align-items-center"
                  @click="copyToClipboard(showInTable(item, column.prop, column.def))"><i
            :class="column.icon ? column.icon : 'fa fa-clipboard'"></i></button>
          <img v-if="column.type === 'img' && showInTable(item, column.prop, column.def)"
               :src="showInTable(item, column.prop, column.def)" class="img-avatar img-avatar32" alt="">
          <template v-if="column.type === 'bool'">
            <i v-if="showInTable(item, column.prop, column.def)" class="text-success fa fa-check"></i>
            <i v-else class="text-danger fa fa-times"></i>
          </template>
        </td>
        <td v-if="$scopedSlots.actions" class="text-center">
          <div class="btn-group">
            <slot :row="item" name="actions"></slot>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <ul v-if="data.meta?.last_page" class="pagination">
      <li class="page-item" :class="{disabled: data.meta.current_page === 1}">
        <a class="page-link" @click="previousPage()" href="javascript:void(0)" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>

      <li v-if="data.meta.current_page > 4" style="letter-spacing: 2px">...</li>

      <li v-for="page in previousPages" :key="'page-' + page"
          class="page-item"><a @click="setPage(page)" class="page-link" href="javascript:void(0)">{{ page }}</a></li>

      <li :key="'page-' + data.meta.current_page"
          class="page-item active"><a @click="setPage(page)" class="page-link" href="javascript:void(0)">{{ data.meta.current_page }}</a></li>

      <li v-for="page in nextPages" :key="'page-' + page"
          class="page-item"><a @click="setPage(page)" class="page-link" href="javascript:void(0)">{{ page }}</a></li>

      <li v-if="data.meta.current_page + 3 < this.data.meta.last_page" style="letter-spacing: 2px">...</li>

      <li class="page-item" :class="{disabled: data.meta.current_page === data.meta.last_page}">
        <a class="page-link" @click="nextPage()" href="javascript:void(0)" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
    <p>Total pages: {{this.data.meta?.last_page}}</p>
  </div>
</template>

<script>
// import ApiService from '@/services/api.service'

export default {
  props: {
    entityName: {
      type: String,
      default: 'Table'
    },
    loading: Boolean,
    data: Object,
    pagination: Object,
    showColumns: Array
  },
  data () {
    return {
      search: ''
    }
  },
  name: 'ntmTable',
  mounted () {
    // this.fetchData()
  },
  watch: {
    search: function () {
      this.fetchData()
    }
  },
  computed: {
    previousPages () {
      const currentPage = this.data.meta?.current_page ?? 1
      const start = Math.max(1, currentPage - 3)
      return Array.from({ length: currentPage - start }, (_, i) => start + i)
    },
    nextPages () {
      const currentPage = this.data.meta?.current_page ?? 1

      const end = Math.min(this.data?.meta?.last_page ?? 0, currentPage + 3)
      return Array.from({ length: end - currentPage }, (_, i) => currentPage + 1 + i)
    }
  },
  methods: {
    copyToClipboard (text) {
      this.$copyText(text).then(() => {
        this.$toast.fire({
          title: 'Copied to clipboard',
          icon: 'success'
        })
      })
    },
    columnClass (column) {
      return {
        'text-center': this.centerColumn(column) || column.align === 'center',
        'text-right': column.align === 'right',
        'text-left': column.align === 'left',
        'column-img': column.type === 'img'
      }
    },
    centerColumn (column) {
      return column.type === 'bool'
    },
    showInTable (item, prop, def) {
      if (item === undefined || item === null) {
        return def || ''
      }
      const index = prop.indexOf('.')
      if (index > -1) {
        return this.showInTable(item[prop.substring(0, index)], prop.substr(index + 1), def)
      }

      return item[prop]
    },
    nextPage () {
      const currentPage = this.data.meta.current_page
      const lastPage = this.data.meta.last_page

      if (currentPage + 10 > lastPage) {
        this.setPage(lastPage)
      } else {
        this.setPage(currentPage + 10)
      }
    },
    previousPage () {
      const currentPage = this.data.meta.current_page

      if (currentPage - 10 < 1) {
        this.setPage(1)
      } else {
        this.setPage(currentPage - 10)
      }
    },
    setPage (page) {
      this.$emit('pageChange', page)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
li.disabled a {
  pointer-events: none;
}

.table-wrapper {
  position: relative;
  min-height: 300px;
}

.table-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.table-loader-icon {
  font-size: 70px;
}
</style>

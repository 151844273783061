import Index from '@/views/users/Index'
import Create from '@/views/users/Create'
import Edit from '@/views/users/Edit'

export default [
  {
    path: '/users',
    name: 'users.index',
    component: Index
  },
  {
    path: '/users/create',
    name: 'users.create',
    component: Create,
    meta: {
      title: 'Create user',
      breadcrumb: [
        {
          name: 'Users',
          route: {
            name: 'users.index'
          }
        },
        {
          name: 'Create'
        }
      ]
    }
  },
  {
    path: '/users/:uuid',
    name: 'users.edit',
    component: Edit,
    meta: {
      title: 'Edit users',
      breadcrumb: [
        {
          name: 'Users',
          route: {
            name: 'users.index'
          }
        },
        {
          name: 'Edit'
        }
      ]
    }
  }
]

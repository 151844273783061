<template>
  <div class="user_index">
    <page-header :title="$tc('user.name', 2)">
    </page-header>
    <page-content>
      <ntm-block>
        <template slot="options">
          <input type="text" class="form-control" :placeholder="$tc('search')"  v-model="search">
          <button @click="createPage()" type="button" class="btn btn-sm btn-success">{{$t('action.addNew')}}</button>
<!--          <button @click="exportAll()" type="button" :title="$t('action.export')" class="btn btn-sm btn-warning"> <i class="fa fa-file" aria-hidden="true"></i></button>-->
        </template>
        <div class="table-responsive">
          <ntm-table :show-columns="showColumns" :data="model" @pageChange="setPage" :loading="loading">
            <template slot="actions" slot-scope="{row}">
              <router-link :to="{ name: 'users.edit', params: { uuid: row.uuid }}" class="btn btn-sm btn-primary">
                <i class="fa fa-fw fa-pencil-alt"></i>
              </router-link>
            </template>
          </ntm-table>
        </div>
      </ntm-block>
      <!-- END Full Table -->
    </page-content>
  </div>
</template>
<script>
import UserService from '@/services/user.service'
import _ from 'lodash'
import router from '@/router'
import basicIndexMixin from '@/mixins/basic.index.mixin'
export default {
  mixins: [basicIndexMixin],
  data () {
    return {
      showColumns: [
        {
          name: 'name',
          prop: 'name'
        },
        {
          name: 'fantasyName',
          prop: 'fantasyName'
        },
        {
          name: 'email',
          prop: 'email'
        },
        {
          name: 'super_admin',
          prop: 'isSuperAdmin',
          type: 'bool'
        }
      ]
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData: _.debounce(function () {
      UserService.index(this.search, this.page).then((response) => {
        this.setData(response.data)
      })
    }, 200),
    createPage () {
      router.push({ name: 'users.create' })
    }
  }
}
</script>

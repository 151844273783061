import ApiService from './api.service'

const ClientService = {
  index (search = '', page = 1) {
    return ApiService.get('api/super-admin/clients', {
      params: {
        search,
        page
      }
    })
  },

  show (uuid) {
    return ApiService.get('api/super-admin/clients/' + uuid)
  },

  countGames (uuid, data) {
    return ApiService.postWithoutLoading('api/super-admin/clients/' + uuid + '/countGames', data)
  },

  update (uuid, data) {
    return ApiService.put('api/super-admin/clients/' + uuid, data)
  },

  store (data) {
    return ApiService.post('api/super-admin/clients', data)
  },

  setCurrentLocalhostTenant (uuid) {
    return ApiService.postWithoutLoading('api/super-admin/clients/' + uuid + '/set-current-localhost-tenant')
  }
}

export default ClientService

<template>
  <div class="about">
    <page-header :title="$t('user.create')" :desc="model.name">
      <li class="breadcrumb-item"><router-link :to="{name:'users.index'}">{{$tc('user.name', 2)}}</router-link></li>
      <li class="breadcrumb-item">{{$t('action.createNew')}}</li>
    </page-header>
    <page-content>
      <form @submit.prevent="onSubmit()">
        <ntm-block>
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <button class="btn btn-success push-15">{{$t('save')}}</button>
        </ntm-block>
      </form>
    </page-content>
  </div>
</template>

<script>
import UserService from '@/services/user.service'
import router from '@/router'

export default {
  // required properties, field, form, model
  data () {
    return {
      form: {},
      model: {},
      fields: [
        {
          key: 'firstName',
          type: 'input',
          templateOptions: {
            label: 'firstName'
          }
        },
        {
          key: 'lastName',
          type: 'input',
          templateOptions: {
            label: 'lastName'
          }
        },
        {
          key: 'email',
          type: 'input',
          templateOptions: {
            inputType: 'email',
            label: 'email'
          }
        },
        {
          key: 'password',
          type: 'input',
          templateOptions: {
            inputType: 'password',
            label: 'password'
          }
        },
        {
          key: 'password_confirmation',
          type: 'input',
          templateOptions: {
            inputType: 'password',
            label: 'password_confirmation'
          }
        }
      ]
    }
  },
  methods: {
    onSubmit () {
      UserService.store(this.model).then((response) => {
        router.push({ name: 'users.edit', params: { uuid: response.data.uuid } })
      }).catch((err) => {
        this.form.$errors = err.response.data.errors
      })
    }
  }
}
</script>
